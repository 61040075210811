.video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 110%;
  height: 85vh;
  overflow: hidden;
}

#main-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.video {
  width: 110%;
}
